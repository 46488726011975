import React, { useState } from "react";
import Layout from "../layout/Layout";
import { useCopyToClipboard } from "usehooks-ts";
import SyntaxHighlighter from "react-syntax-highlighter";
import atelierCaveDark from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-cave-dark";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DoneIcon from "@mui/icons-material/Done";

const PaiementDiffere = () => {
  const [copy] = useCopyToClipboard();
  const [isCopiedCode2, setIsCopiedCode2] = useState(false);
  const [isCopiedResponse2, setIsCopiedResponse2] = useState(false);
  const [isCopiedRequeste, setIsCopiedRequeste] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("python");

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const data1 = [
    {
      key: "utilisateur",
      label: "serviceId",
      description:
        " le serviceId est l'identifiant du service fournit par SkanPayment",
      value: "XXXXXXXX",
    },
    {
      key: "utilisateur",
      label: "utilisateur",
      description:
        "Le nom d'utilisateur associé au compte fournit par SkanPayement",
      value: "UTILISATEUR",
    },
    {
      key: "password",
      label: "password",
      description: "Le mot de passe associé au compte fournit par SkanPayement",
      value: "PASSWORD",
    },
    {
      key: "codeOperateur",
      label: "codeOperateur",
      description:
        "Code d'opérateur  liée au service, dans notre cas 'MOOV_SKAN', 'MOMO_SKAN' pour Moov money et MTN money.",
      value: "MOOV_SKAN, MOMO_SKAN",
    },
    {
      key: "référence",
      label: "reference",
      description:
        "Une référence est un numéro unique fournit pas l'opérateur associé à l'opération en cours.",
      value: "YYYYYYYYY",
    },
    {
      key: "numero",
      label: "numero",
      description: "Numéro de téléphone, à débiter.",
      value: "XXXXXXXXXX",
    },
    {
      key: "montant",
      label: "montant",
      description: "Montant associé à la transaction",
      value: "MONTANT",
    },
    {
      key: "typeOperateur",
      label: "typeOperateur",
      description: "La valeur reste obligatoirement NON_REDIRIGE",
      value: "NON_REDIRIGE",
    },
    {
      key: "nom",
      label: "nom",
      description: "Le nom du titulaire du client (facultatif).",
      value: "NOM",
    },
    {
      key: "email",
      label: "email",
      description: "L'adresse e-mail associée à au client (facultatif).",
      value: "xxx@gmail.com",
    },
    {
      key: "numeroClient",
      label: "numeroClient",
      description: "Un autre numéro associé clients (facultatif)",
      value: "XXXXXXXX",
    },
    {
      key: "token",
      label: "token",
      description:
        "Le token est généré par le client via une syntaxe fournit par l'opérateur (laisser vide)",
      value: "",
    },
  ];

  const codeSnippets2 = {
    python: `import http.client

    conn = http.client.HTTPSConnection("api-public-2.mtarget.fr")
    
    payload = "username=USERNAME&password=PASSWORD&msisdn=MSISDN&msg=Message%20simple"
    
    headers = { 'content-type': "application/x-www-form-urlencoded" }
    
    conn.request("POST", "/messages", payload, headers)
    
    res = conn.getresponse()
    data = res.read()
    
    print(data.decode("utf-8"))`,
    java: `HttpResponse<String> response = Unirest.post("https://api-public-2.mtarget.fr/messages")
    .header("content-type", "application/x-www-form-urlencoded")
    .body("username=USERNAME&password=PASSWORD&msisdn=MSISDN&msg=Message%20simple")
    .asString();`,
    react: `var data = "username=USERNAME&password=PASSWORD&msisdn=MSISDN&msg=Message%20simple";

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });
    
    xhr.open("POST", "https://api-public-2.mtarget.fr/messages");
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");
    
    xhr.send(data);`,
  };

  return (
    <Layout>
      <div className="relative w-full ">
        <div className="absolute z-50 top-4 right-10  ">
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="select select-bordered text-white bg-black w-full max-w-xs"
          >
            <option selected value="python">
              Python
            </option>
            <option value="java">Java</option>
            <option value="react">React</option>
          </select>
        </div>
        <div className="content z-30 relative pt-[2rem] h-[100vh] overflow-y-auto pb-[5rem] md:pb-0 lg:pb-0">
          <div className=" flex flex-col gap-4 mt-6 md:mt-0 lg:mt-[2rem] pb-6 pl-[0.5rem] md:pl-[2rem] lg:pl-[2rem] md:mr-[50%] lg:mr-[50%] mr-0 clear-right   ">
            <h2
              id="paiementDirect"
              className=" border-l-[3px] border-yellow-500  pl-8 text-[25px] font-[700]   "
            >
              Paiement avec réponse différée
            </h2>
            <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px] " />
            <div className="flex flex-col gap-4">
              <div className="flex flex-row">
                <p className="text-[18px] text-[#48494a] ">
                  Dans le cas d'un paiement avec reponse différée, après la
                  requete de paiement, SkanPayment attend la validation du
                  paiement par le client et le{" "}
                  <span className=" font-bold">code</span> est a la valeur 10.
                  Toute valeur du <span className=" font-bold">code</span>{" "}
                  différente de 10 signifie un echec du paiement. La cause de
                  l'échec est spécifiée dans le{" "}
                  <span className=" font-bold"> message </span>
                  de la reponse.
                </p>
              </div>

              <div className="bg-[#eff7f2] px-2 py-2 ">
                <p className=" flex flex-row gap-3 ">
                  <span className="bg-[#5eb583] flex items-center text-white py-1 px-2 rounded-lg">
                    POST
                  </span>{" "}
                  <span className="md:block lg:block hidden">
                    https://paiement.skanci.com/mobilepayment/payer
                  </span>
                  <span className="md:hidden lg:hidden flex">
                    https://paiement.skanci.com /mobilepayment/payer
                  </span>
                </p>
              </div>
              <div className="overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th>HEADERS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b-[0]">
                      <td className="font-bold">Content-Type</td>
                      <td>application/x-www-form-urlencoded</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th>BODY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data1.map((item) => (
                      <tr key={item.key} className="border-b-[0]">
                        <td className="font-bold ">{item.label}</td>
                        <td>{item.value}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className=" relative my-2 md:mt-[-35rem] lg:mt-[-80rem] mt-0 md:float-right lg:float-right float-none clear-left md:w-[41%] lg:w-[41%] w-[90%] mx-auto bg-[#101011] md:mr-[1rem] lg:mr-[1rem] mr-[1rem] text-[#eee] overflow-x-auto lg:overflow-hidden rounded-lg">
              <div className="flex flex-row px-2 justify-between bg-[#1d1d1e] border-b-[2px]">
                <span>{selectedLanguage}</span>
                <button
                  onClick={() => {
                    setIsCopiedCode2(true);
                    copy(codeSnippets2[selectedLanguage]);
                    setTimeout(() => {
                      setIsCopiedCode2(false);
                    }, 2000);
                  }}
                >
                  {" "}
                  {isCopiedCode2 ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ContentPasteIcon fontSize="small" />
                  )}
                </button>
              </div>
              <SyntaxHighlighter
                language={selectedLanguage}
                style={atelierCaveDark}
                wrapLongLines={true}
              >
                {codeSnippets2[selectedLanguage]}
              </SyntaxHighlighter>
            </div>
            <div className=" relative  md:float-right lg:float-right float-none clear-right md:w-[41%] lg:w-[41%] w-[90%] mx-auto bg-[#101011] md:mr-[1rem]  lg:mr-[1rem] mr-[1rem] text-[#eee] overflow-x-auto lg:overflow-hidden rounded-lg">
              <div className="flex flex-row px-2 justify-between bg-[#1d1d1e] border-b-[2px]">
                <span>
                  reponse:{" "}
                  <span className="bg-green-600 px-2 rounded-lg text-[10px]">
                    200 OK
                  </span>
                </span>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(`
                    {
                      "code": 10,
                      "message": "Paiement en attente de validation"
                    }`);
                    setIsCopiedResponse2(true);
                    setTimeout(() => {
                      setIsCopiedResponse2(false);
                    }, 3000);
                  }}
                >
                  {" "}
                  {isCopiedResponse2 ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ContentPasteIcon fontSize="small" />
                  )}
                </button>
              </div>
              <SyntaxHighlighter
                language="ruby"
                style={atelierCaveDark}
                showLineNumbers={true}
                wrapLongLines={true}
              >
                {`{
"code": 10,
"message": "Paiement en attente de validation"
}`}
              </SyntaxHighlighter>
            </div>
          </div>

          <div className=" flex flex-col gap-4 mt-6 md:mt-0 lg:mt-[2rem] pb-6 pl-[0.5rem] md:pl-[2rem] lg:pl-[2rem] md:mr-[50%] lg:mr-[50%] mr-0 clear-right   ">
            <div className="flex flex-row">
              <p className="text-[18px] text-[#48494a] ">
                Après soumission de la requête de payement le client reçoit un
                SMS ou une notification push pour la validation du payement.{" "}
                <br></br>
                Une fois la validation du paiement effectué par le client, l'URL
                de notification fournit par le service est appélée par
                SkanPayment par une requete HTTP POST{" "}
                <span className="bg-[#2a9292] text-white py-1 px-2 rounded-lg">
                  JSON{" "}
                </span>{" "}
              </p>
            </div>
            <div className="bg-[#eff7f2] px-2 py-2 flex flex-nowape">
              <p>
                <span className="bg-[#5eb583] text-white py-1 px-2 rounded-lg">
                  POST
                </span>{" "}
                Url [urlNotificationService]
              </p>
            </div>
          </div>
          <div className=" relative md:mt-[-10rem] mb-[5rem] lg:mt-[-10rem] mt-0  md:float-right lg:float-right float-none clear-right md:w-[41%] lg:w-[41%] w-[90%] mx-auto bg-[#101011] md:mr-[1rem]  lg:mr-[1rem] mr-[1rem] text-[#eee] overflow-x-auto lg:overflow-hidden rounded-lg">
            <div className="flex flex-row px-2 justify-between bg-[#1d1d1e] border-b-[2px]">
              <span>requête</span>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(`
                    {
                      "reference": "YYYYYYYYY",
                      "code": 0,
                      "message": "Paiement effectué avec succès"
                    }`);
                  setIsCopiedRequeste(true);
                  setTimeout(() => {
                    setIsCopiedRequeste(false);
                  }, 3000);
                }}
              >
                {" "}
                {isCopiedRequeste ? (
                  <DoneIcon fontSize="small" />
                ) : (
                  <ContentPasteIcon fontSize="small" />
                )}
              </button>
            </div>
            <SyntaxHighlighter
              language="ruby"
              style={atelierCaveDark}
              showLineNumbers={true}
              wrapLongLines={true}
            >
              {`{
  "reference": "YYYYYYYYY",
  "code": 0,
  "message": "Paiement effectué avec succès"
}`}
            </SyntaxHighlighter>
          </div>
        </div>
        <div className="boxDark md:absolute lg:absolute w-[45%] bg-[#282a36] h-full top-0 right-0 bottom-0"></div>
      </div>
    </Layout>
  );
};

export default PaiementDiffere;
