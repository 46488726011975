import React from "react";
import {
  // HashRouter,
  // createBrowserRouter,
  createHashRouter,
} from "react-router-dom";
import Presentation from "../views/Presentation";
import Prerequis from "../views/Prerequis";
import PaiementDirect from "../views/PaiementDirect";
import PaiementDiffere from "../views/PaiementDiffere";
import PaiementRedirige from "../views/PaiementRedirige";

const Router = createHashRouter([
  {
    path: "/prerequis",
    element: <Prerequis />,
  },
  {
    path: "/",
    element: <Presentation />,
  },

  {
    path: "/paiement_direct",
    element: <PaiementDirect />,
  },
  {
    path: "/paiement_differe",
    element: <PaiementDiffere />,
  },
  {
    path: "/paiement_redirige",
    element: <PaiementRedirige />,
  },
]);

export default Router;
