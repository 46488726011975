import Layout from "../layout/Layout";
import { Link } from "react-router-dom";
import TagIcon from "@mui/icons-material/Tag";
import "../styles/prerequis.css";
import ScrollToAnchor from "../ScrollToAnchor";

const Prerequis = () => {


  return (
    <Layout>
      <div className=" relative w-full  flex flex-col justify-between pb-[4rem]  md:pl-[2rem] pr-2 lg:pl-[2rem] pl-[1rem] h-[100vh]  overflow-y-auto">
        <div className=" flex flex-col gap-4 w-full md:w-[70%] lg:w-[70%] mt-[4rem]">
          <h2 className=" border-l-[3px] border-yellow-500  pl-8 text-[25px] font-[700]  ">
            Prérequis
          </h2>
          <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
        </div>
        <ul className="fixed top-1 right-3 menu lg:flex md:flex hidden gap-y-4 mt-[2rem] w-56 rounded-box">
          <li>
            <summary className="text-[14px] font-bold text-[#b8c2cc]">
              Prérequis
            </summary>
            <ul className="flex flex-col gap-y-2">
              <li>
                <Link
                  to="/prerequis#compte_utilisateur"
                  className="active-link text-[14px] text-[#b8c2cc]"
                >
                  Compte utilisateur
                </Link>
              </li>
              <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
              <li>
                <Link
                  to="/prerequis#service_marchand"
                  className="active-link text-[14px] text-[#b8c2cc]"
                >
                  Service Marchand
                </Link>
              </li>
              <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
              <li>
                <Link
                  to="/prerequis#paiement"
                  className="active-link text-[14px] text-[#b8c2cc]"
                >
                  Paiements
                </Link>
              </li>
              <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
            </ul>
          </li>
        </ul>

        <div className="flex flex-col gap-[2rem] w-full md:w-[70%] lg:w-[70%] pr-2 ">
          <div
            id="compte_utilisateur"
            className="flex mt-[2rem] flex-row items-center gap-4 "
          >
            <TagIcon fontSize="medium" className="text-yellow-500" />
            <h2 className="text-[23px] font-medium text-[#48494a] ">
              Compte utilisateur
            </h2>
          </div>
          <p className="text-[18px]">
            Tout intégrateur de SkanPayment doit disposer préalablement d'un
            compte. Les paramètres du compte (Login, Password) sont fournis par
            SkanPayment à l'intégrateur.
          </p>
          <div
            id="service_marchand"
            className="flex flex-row items-center gap-4 "
          >
            <TagIcon fontSize="medium" className="text-yellow-500" />
            <h2 className="text-[23px] font-medium text-[#48494a] ">
              Service marchand
            </h2>
          </div>
          <p className="text-[18px]">
            Un service marchand est un service pour lequel l'intégrateur
            aimerait utiliser SkanPayment comme moyen de paiement. Chaque
            service est configuré sur SkanPayment et est identifié par un ID
            unique <span className="font-bold">(serviceId)</span>. Cet ID,
            fournit par SkanPayment, est utlisé dans la requete de paiement.
            Aussi, pour chaque service, l'intégrateur fournit à SkanPayment une
            URL de notification. Cette URL est appelée par SkanPayment par une
            requête HTTP POST pour notifier du succès ou de l'échec de la
            requête de paiement
          </p>
          <div className="flex flex-row items-center gap-4 ">
            <TagIcon fontSize="medium" className="text-yellow-500" />
            <h2
              id="paiement"
              className="text-[23px] font-medium text-[#48494a] "
            >
              Paiement
            </h2>
          </div>
        
          <p className="text-[18px]">
            La requête de paiement se fait par une requête HTTP{" "}
            <span className="bg-[#5eb583] text-white py-1 px-2 rounded-lg">
              POST
            </span>
            . Le corps de la requête est au format{" "}
            <span className="bg-[#2a9292] text-white py-1 px-2 rounded-lg">
              form-urlencoded
            </span>
            . La reponse à la requête est au format{" "}
            <span className="bg-[#2a9292] text-white py-1 px-2 rounded-lg">
              JSON{" "}
            </span>{" "}
            et elle contient deux entrées. Le{" "}
            <span className=" font-bold">code</span>, qui definit le statut de
            la reponse, et le
            <span className="font-bold"> message </span>, qui decrit le statut.
          </p>
          {/* <div className=" relative  md:w-[90%] lg:w-[90%] w-full md:mr-[1rem] lg:mr-[1rem] mr-[1rem] bg-[#101011]  text-[#eee] pt-[3rem] rounded-lg">
            <div className="absolute flex justify-end top-0  w-full ">
              <button
                className=" hover:bg-[#2f2f2f] text-white px-2 py-1 rounded-md"
                onClick={() => {
                  setIsCopiedUrl(true);
                  copy("https://paiement.skanci.com/mobilepayment/");
                  setTimeout(() => {
                    setIsCopiedUrl(false);
                  }, 2000);
                }}
              >
                {isCopiedUrl ? (
                  <DoneIcon fontSize="small" />
                ) : (
                  <ContentPasteIcon fontSize="small" />
                )}
              </button>
            </div>
            <pre className="overflow-auto flex justify-center px-2 h-[10vh]">
              <code>https://paiement.skanci.com/mobilepayment/</code>
            </pre>
          </div> */}
          <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
        </div>

        <div className="flex justify-between pb-[4rem] w-full pr-2 md:w-[70%] lg:w-[70%] mt-4">
          <Link
            to="/"
            className=" bg-yellow-500 text-white py-1 px-2 rounded-lg"
          >
            Précédent
          </Link>

          <Link
            to="/paiement_direct"
            className=" bg-yellow-500 text-white py-1 px-2 rounded-lg"
          >
            Suivant
          </Link>
        </div>
      </div>

      <ScrollToAnchor />
    </Layout>
  );
};

export default Prerequis;
