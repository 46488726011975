import React, { useRef, useState } from "react";
import Layout from "../layout/Layout";
import { useCopyToClipboard } from "usehooks-ts";
import SyntaxHighlighter from "react-syntax-highlighter";
import atelierCaveDark from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-cave-dark";
import { Link } from "react-router-dom";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DoneIcon from "@mui/icons-material/Done";

const PaiementDirect = () => {
  const [value, copy] = useCopyToClipboard();
  const [isCopiedCode, setIsCopiedCode] = useState(false);
  const [isCopiedResponse, setIsCopiedResponse] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("python");

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };
  const data = [
    {
      key: "utilisateur",
      label: "serviceId",
      description:
      " le serviceId est l'identifiant du service (fournit par SkanPayment)",
      value: "XXXXXXXX",
    },
    {
      key: "utilisateur",
      label: "utilisateur",
      description:
        "Le nom d'utilisateur associé au compte (fournit par SkanPayment)",
      value: "UTILISATEUR",
    },
    {
      key: "password",
      label: "password",
      description:
        "Le mot de passe associé au compte (fournit par SkanPayment)",
      value: "PASSWORD",
    },
    {
      key: "codeOperateur",
      label: "codeOperateur",
      description:
        "Code d'opérateur liée au service .",
      value: "OM_SKAN",
    },
    {
      key: "référence",
      label: "reference",
      description:
        "Une référence est un numéro unique fournit pas l'opérateur associé à l'opération en cours.",
      value: "YYYYYYYYY",
    },
    {
      key: "numero",
      label: "numero",
      description: "Numéro de téléphone, à débiter",
      value: "XXXXXXXXXX",
    },
    {
      key: "montant",
      label: "montant",
      description: "Montant associé à la transaction ou à l'opération.",
      value: "MONTANT",
    },
    {
      key: "typeOperateur",
      label: "typeOperateur",
      description: "Valeur reste obligatorement NON_REDIRIGE",
      value: "NON_REDIRIGE",
    },
    {
      key: "nom",
      label: "nom",
      description: "Le nom du client effectuant le paiement (facultatif)",
      value: "NOM",
    },
    {
      key: "email",
      label: "email",
      description:
        "L'adresse e-mail du client effectuant le paiement   (facultatif)",
      value: "xxx@gmail.com",
    },
    {
      key: "numeroClient",
      label: "numeroClient",
      description:
        "Le numéro du client effectuant le paiement  (facultatif) ",
      value: "XXXXXXXX",
    },
    {
      key: "token",
      label: "token",
      description:
        "Le token est généré par le client via une syntaxe fournit par l'opérateur (#144*82#)",
      value: "XXXX",
    },
  ];

  const codeSnippets = {
    python: `import http.client
    conn = http.client.HTTPSConnection("api-public-2.mtarget.fr")
    conn.request("GET", "/balance?username=USERNAME&password=PASSWORD")
    res = conn.getresponse()
    data = res.read()
    print(data.decode("utf-8"))`,
    java: `HttpResponse<String> response = Unirest.get("https://api-public-2.mtarget.fr/balance?username=USERNAME&password=PASSWORD")
    .asString();`,
    react: `var data = null;
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });
    xhr.open("GET", "https://api-public-2.mtarget.fr/balance?username=USERNAME&password=PASSWORD")
    xhr.send(data);`,
  };

  return (
    <Layout>
      <div className="relative w-full ">
        <div className="absolute z-50 top-4 right-10  ">
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="select select-bordered text-white bg-black w-full max-w-xs"
          >
            <option selected value="python">
              Python
            </option>
            <option value="java">Java</option>
            <option value="react">React</option>
          </select>
        </div>
        <div className="content z-30 relative pt-[4rem]   h-[100vh]  overflow-y-auto pb-[5rem] md:pb-0 lg:pb-0">
          {/* test */}
          <div className=" flex flex-col mt-6 md:mt-0 lg:mt-0 gap-4 pb-6 pl-[0.5rem] md:pl-[2rem] lg:pl-[2rem] md:mr-[50%] lg:mr-[50%] mr-0   ">
            <h2
              id="paiementDirect"
              className=" border-l-[3px] border-yellow-500  pl-8 text-[25px] font-[700]   "
            >
              Paiement avec reponse directe
            </h2>
            <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px] " />
            <div className="flex flex-col gap-4">
              <p className="text-[18px]">
                Dans le cas d'un paiement avec reponse directe, le paiement
                s'est effectué avec succès si le{" "}
                <span className=" font-bold">code</span> de la reponse est égal
                à 0. Toute valeur du <span className=" font-bold">code</span>{" "}
                différente de 0 signifie un echec du paiement. La cause de
                l'échec est spécifiée dans le{" "}
                <span className="font-bold"> message </span> de la reponse.
              </p>
            </div>
          </div>

          <div className=" flex flex-col gap-4 mt-6 md:mt-0  lg:mt-0 pb-6 pl-[0.5rem] md:pl-[2rem] lg:pl-[2rem] md:mr-[50%] lg:mr-[50%] mr-0 clear-right   ">
            <div className="flex flex-col gap-4">
              <div className="bg-[#eff7f2] px-2 py-2 ">
                <p className=" flex flex-row gap-3 ">
                  <span className="bg-[#5eb583] flex items-center text-white py-1 px-2 rounded-lg">
                    POST
                  </span>{" "}
                  <span className="md:block lg:block hidden">
                    https://paiement.skanci.com/mobilepayment/payer
                  </span>
                  <span className="md:hidden lg:hidden flex">
                    https://paiement.skanci.com /mobilepayment/payer
                  </span>
                </p>
              </div>

              <div className="overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th>HEADERS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b-[0]">
                      <td className="font-bold">Content-Type</td>
                      <td>application/x-www-form-urlencoded</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th>BODY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.key} className="border-b-[0]">
                        <td className="font-bold">{item.label}</td>
                        <td>{item.value}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className=" relative my-2 md:mt-[-140rem] lg:mt-[-77rem] mt-0 md:float-right lg:float-right float-none clear-left md:w-[41%] lg:w-[41%] w-[90%] mx-auto bg-[#101011] md:mr-[1rem] lg:mr-[1rem] mr-[1rem] text-[#eee] overflow-x-auto lg:overflow-hidden rounded-lg">
              <div className="flex flex-row px-2 justify-between bg-[#1d1d1e] border-b-[2px]">
                <span>{selectedLanguage}</span>
                <button
                  onClick={() => {
                    setIsCopiedCode(true);
                    copy(codeSnippets[selectedLanguage]);
                    setTimeout(() => {
                      setIsCopiedCode(false);
                    }, 2000);
                  }}
                >
                  {" "}
                  {isCopiedCode ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ContentPasteIcon fontSize="small" />
                  )}
                </button>
              </div>
              <SyntaxHighlighter
                language={selectedLanguage}
                style={atelierCaveDark}
                wrapLongLines={true}
              >
                {codeSnippets[selectedLanguage]}
              </SyntaxHighlighter>
            </div>
            <div className=" relative  md:float-right lg:float-right float-none clear-right md:w-[41%] lg:w-[41%] w-[90%] mx-auto bg-[#101011] md:mr-[1rem]  lg:mr-[1rem] mr-[1rem] text-[#eee] overflow-x-auto lg:overflow-hidden rounded-lg">
              <div className="flex flex-row px-2 justify-between bg-[#1d1d1e] border-b-[2px]">
                <span>
                  reponse:{" "}
                  <span className="bg-green-600 px-2 rounded-lg text-[10px]">
                    200 Ok
                  </span>
                </span>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(`
                    {
                      "code": 0,
                      "message": "Paiement effectué avec succès"
                    }`);
                    setIsCopiedResponse(true);
                    setTimeout(() => {
                      setIsCopiedResponse(false);
                    }, 3000);
                  }}
                >
                  {" "}
                  {isCopiedResponse ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ContentPasteIcon fontSize="small" />
                  )}
                </button>
              </div>
              <SyntaxHighlighter
                language="ruby"
                style={atelierCaveDark}
                showLineNumbers={true}
                wrapLongLines={true}
              >
                {`{
"code": 0,
"message": "Paiement effectué avec succès"
}`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
        <div className="boxDark md:absolute lg:absolute w-[45%] bg-[#282a36] h-full top-0 right-0 bottom-0"></div>
      </div>
    </Layout>
  );
};

export default PaiementDirect;
