import React from "react";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";
import TagIcon from "@mui/icons-material/Tag";
import ScrollToAnchor from "../ScrollToAnchor";

const Presentation = () => {
  return (
    <Layout>
      <div className=" relative w-full  md:pl-[2rem] lg:pl-[2rem] pl-[1rem] h-[100vh] pb-[4rem] overflow-y-auto">
        <div className=" flex flex-col gap-4 pb-6 w-[70%]  mt-[4rem]">
          <h2 className=" border-l-[3px] border-yellow-500  pl-8 text-[25px] font-[700]  ">
            Présentation
          </h2>
          <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
        </div>
        <ul className="fixed top-1 right-3 menu lg:flex md:flex hidden gap-y-4 mt-[2rem] w-56 rounded-box">
          <li>
            <summary className="text-[14px] font-bold text-[#b8c2cc]">
              Présentation
            </summary>
            <ul className="flex flex-col gap-y-2">
              <li>
                <Link to="/#objectif" className="text-[14px] text-[#b8c2cc]">
                  Opérateurs
                </Link>
              </li>
              <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
              
            </ul>
          </li>
        </ul>

        <div className="flex flex-col gap-[2rem] lg:w-[70%] md:w-[70%] w-full  mt-[2rem]">
          <p className="text-[18px]">
            SkanPayment est une passerelle de paiement. Cette documentation
            décrit l'utilisation de SkanPayment.
          </p>
        </div>

        <div className="flex flex-col gap-[2rem] lg:w-[70%] md:w-[70%] w-full   mt-[3rem]">
          <div className="flex flex-row items-center gap-4 ">
            <TagIcon fontSize="medium" className="font-bold text-yellow-500" />
            <h2
              id="objectif"
              className="text-[23px] font-medium text-[#48494a] "
            >
              Opérateurs
            </h2>
          </div>
          <p className="text-[18px]">
            SkanPayment est connecté à plusieurs opérateurs qui implementent
            chacun le paiement d'une facon spécifique. Il existe principalement
            trois implementations:
            <br />
            <br />
            <ul>
              <li>
                <span className="font-bold">
                  Paiement avec reponse directe :
                </span>{" "}
                la reponse à la requete de paiement se trouve dans la reponse à
                la requete HTTP.
              </li>

              <li>
                <span className="font-bold">
                  Paiement avec reponse différée :
                </span>{" "}
                la reponse à la requete de paiement se fait par l'appel d'une
                url de callback du service qui utilise l'API.
              </li>

              <li>
                <span className="font-bold">
                  Paiement avec reponse redirigée :
                </span>{" "}
                la reponse à la requête de paiement se fait par l'appel d'une
                url de callback du service qui utilise l'API.
              </li>
              
            </ul>
            <br />
            <br />
            Chaque opérateur dispose d'un code qui est utilisé dans la requête
            de paiement. Les opérateurs suivants sont integrés à SkanPayment:
            <ul>
              <li>Orange Money CI (Paiement avec réponse directe) : <span className="font-bold text-yellow-500">OM_SKAN</span> </li>{" "}
              <li>
                {" "}
                MTN Money CI (Paiement avec réponse différée) :<span className="font-bold text-yellow-500"> MOMO_SKAN</span>
              </li>{" "}
              <li>
                Moov Money CI (Paiement avec réponse différée) : <span className="font-bold text-yellow-500">MOOV_SKAN</span>
              </li>{" "}
              <li>
                Wave CI (Paiement avec réponse redirigée) : <span className="font-bold text-yellow-500">WAVE_SKAN</span>
              </li>{" "}
            </ul>
          </p>

          <hr className=" border-t-[2px] border-dashed border-gray-200 h-[2px]" />
        </div>

        <div className="flex justify-end pr-4 pb-[4rem] lg:w-[70%] md:w-[70%] w-full  mt-4">
          <Link
            to="/prerequis"
            className=" bg-yellow-500 text-white py-1 px-2 rounded-lg"
          >
            Suivant
          </Link>
        </div>
      </div>


      <ScrollToAnchor/>
    </Layout>
  );
};

export default Presentation;
